<template>
    <div class="towers-row" v-bind:class="{
        'row-active': towersGame !== null && towersGame.state !== 'completed' && towersGame.revealed.length === row,
        'row-revealed': towersGame !== null && towersGame.revealed[row] !== undefined
    }">
        <div v-for="tile in towersGetTilesCount" v-bind:key="tile" class="row-tile">
            <transition name="fade" mode="out-in">
                <div v-if="towersGame !== null && towersGame.revealed[row] !== undefined && towersGame.revealed[row].tile === (tile - 1) && towersGame.revealed[row].row[tile - 1] === 'coin'" class="tile-coin">
                    <div class="coin-inner">
                        <div class="inner-value">
                        </div>
                        <img class="tile-mine" src="https://hoon.gg/newitems/star.png" />
                    </div>
                </div>
                <div v-else-if="
                        towersGame !== null && 
                        (
                            (towersGame.revealed[row] !== undefined && towersGame.revealed[row].tile === (tile - 1) && towersGame.revealed[row].row[tile - 1] === 'lose') ||
                            (towersGame.state === 'completed' && towersGame.revealed.length <= row && towersGame.deck[row][tile - 1] === 'lose')
                            
                        )" class="coin-inners">
                    <img class="tile-mine" src="https://hoon.gg/newitems/denied.png" />
                </div>
                <button 
                    v-else 
                    v-on:click="towersRevealButton(tile - 1)" 
                    class="button-reveal" 
                    v-bind:disabled="socketSendLoading !== null || towersGame === null || towersGame.state === 'completed' || towersGame.revealed.length !== row"
                >
                    <div class="button-inner">
                        <div class="inner-value">
                            <span>{{ towersFormatValue(towersGetRowCashout).split('.')[0] }}</span>.{{ towersFormatValue(towersGetRowCashout).split('.')[1] }}
                        </div>
                    </div>
                </button>
            </transition>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconSkull from '@/components/icons/IconSkull';

    export default {
        name: 'TowersRow',
        components: {
            IconSkull
        },
        props: [
            'row'
        ],
        methods: {
            ...mapActions([
                'towersSendRevealSocket'
            ]),
            towersFormatValue(value) {
                return parseFloat((value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            towersRevealButton(tile) {
                const data = { tile: tile };
                //console.log(tile);
                this.towersSendRevealSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading',
                'towersRisk',
                'towersGame'
            ]),
            towersGetTilesCount() {
                let count = 3;

                if((this.towersGame !== null && this.towersGame.state !== 'completed' ? this.towersGame.risk : this.towersRisk) === 'hard') {
                    return 2;
                }

                if((this.towersGame !== null && this.towersGame.state !== 'completed' ? this.towersGame.risk : this.towersRisk) === 'easy') {
                    return 4;
                }

                if((this.towersGame !== null && this.towersGame.state !== 'completed' ? this.towersGame.risk : this.towersRisk) === 'master') {
                    return 4;
                }

                return count;
            },
            towersGetRowCashout() {
                let multiplierPerRow = this.towersRisk === 'easy' ? 1.31 : this.towersRisk === 'medium' ? 1.47 : 1.96;
                if (this.towersRisk === 'easy') {
                    multiplierPerRow = 1.31;
                } else if (this.towersRisk === 'medium') {
                    multiplierPerRow = 1.47;
                } else if (this.towersRisk === 'hard') {
                    multiplierPerRow = 1.96;
                } else if (this.towersRisk === 'expert') {
                    multiplierPerRow = 2.94;
                } else if (this.towersRisk === 'master') {
                    multiplierPerRow = 3.92;
                }

                const amount = this.towersGame !== null ? this.towersGame.amount : 0;

                return amount * Math.pow(multiplierPerRow, this.row + 1);
            }
        }
    }
</script>

<style scoped>
    .towers-row {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 18px;
        padding: 0 30px;
    }

    .towers-row:last-child {
        margin-top: 0;
    }

    .towers-row.row-active::before,
    .towers-row.row-active::after {
        content: '';
        width: 3px;
        height: 45px;
        position: absolute;
        top: 0;
        border-radius: 3px;
        background: linear-gradient(255deg, #ff3a3a -20%, #ff3a3a 100%);
    }

    .towers-row.row-active::before {
        left: 0;
    }

    .towers-row.row-active::after {
        right: 0;
        
    }

    .towers-row .row-tile {
        width: calc(33.33% - 12px);
        height: 45px;
    }

    .container-game.game-hard .towers-row .row-tile {
        width: calc(50% - 8px);
    }

    .container-game.game-easy .towers-row .row-tile {
        width: calc(25% - 8px);
    }

    .container-game.game-master .towers-row .row-tile {
        width: calc(25% - 8px);
    }

    .towers-row  .tile-coin,
    .towers-row  .tile-lose {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 1px;
        z-index: 1;
        
    }

    .towers-row.row-active  .tile-coin,
    .towers-row.row-active  .tile-lose {
        opacity: 1;
    }

    .towers-row  .tile-coin::before,
    .towers-row  .tile-lose::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: inset(0 round 8px);
        z-index: -1;
    }

    .towers-row  .tile-coin::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(30, 112, 19) 100%);
    }

    .towers-row  .tile-lose::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #f55046 100%);
    }

    .towers-row  .tile-coin::after,
    .towers-row  .tile-lose::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #252525;
        clip-path: inset(0 round 8px);
        z-index: -1;
    }

    .towers-row .coin-inner,
    .towers-row .coin-inners,

    .towers-row .lose-inner {
        width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    overflow: hidden;
    position: relative;  
    
   }

    .towers-row .coin-inner {
        width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    overflow: hidden;
    position: relative;  
    }
    .towers-row .coin-inners {
        width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    overflow: hidden;
    position: relative;  
    }


    .towers-row .coin-inner {
        width: 100%;
        height: 100%;
        background: #149445;
        border-radius: 10px;
    }
    .towers-row .coin-inners {
        width: 100%;
        height: 100%;

        background: #ee5555;
        border-bottom: 5px solid #a12a2a;
        border-radius: 10px;
    }

    .towers-row .lose-inner {
        width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    overflow: hidden;
    position: relative;  


    }

    .towers-row .lose-inner {
        width: 146px;
        height: 47px;

        background: #f14b4b;
        border-bottom: 5px solid #c44949;
        border-radius: 10px;
    }

    .towers-row button.button-reveal {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border-bottom: 5px solid #701c1c;
        background: #a02121;
                transition: transform 0.3s;

    }

    .towers-row.row-active button.button-reveal {
        transition: transform 0.3s ease;
    }

    .towers-row.row-active button.button-reveal:hover {
        transform: scale(1.05);
    }

    .towers-row button.button-reveal .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        clip-path: inset(0 round 8px);
        
    }

    .towers-row.row-active button.button-reveal .button-inner,
    .towers-row.row-revealed button.button-reveal .button-inner {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), 
                    radial-gradient(160% 160% at 50% 30%, rgba(255, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), 
                    linear-gradient(255deg, #641b1b 0%, #641b1b 100%);
                    
    }

    .towers-row.row-revealed button.button-reveal .button-inner {
        opacity: 0.25;
        
    }

    .towers-row button.button-reveal .button-inner img,
    .towers-row .coin-inner img {
        height: 30px;
    }
    .towers-row .coin-inners img {
        height: 30px;
    }

    .towers-row button.button-reveal .button-inner .inner-value,
    .towers-row .coin-inner .inner-value  {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
        font-family: 'Roboto', sans-serif;
        
    }
    .towers-row .coin-inners .inner-value  {
        font-size: 11px;
        font-weight: 600;
        color: #bbbfd0;
        font-family: 'Roboto', sans-serif;
    }

    .towers-row button.button-reveal .button-inner .inner-value span,
    .towers-row .coin-inner .inner-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
        font-family: 'Roboto', sans-serif;
    }
    .towers-row .coin-inners .inner-value span {
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
        font-family: 'Roboto', sans-serif;
    }

    .towers-row .coin-inner img.image-knight {
        width: 46px;
        height: auto;
        position: absolute;
        top: 4px;
        right: -3px;
        transform: scaleX(-1);
    }
    .towers-row .coin-inners img.image-knight {
        width: 46px;
        height: auto;
        position: absolute;
        top: 4px;
        right: -3px;
        transform: scaleX(-1);
    }
    @media only screen and (max-width: 550px) {

        .towers-row {
            padding: 0 15px;
        }

        .towers-row .row-tile {
            width: calc(33.33% - 6px);
        }

    }

    @media only screen and (max-width: 500px) {

        .towers-row .coin-inner {
            padding: 0;
        }

        .towers-row .coin-inners {
            padding: 0;
        }
        .towers-row .coin-inner img.image-knight {
            display: none;
        }
        .towers-row .coin-inners img.image-knight {
            display: none;
        }
    }
</style>
