<template>
    <div class="home">
        <div class="home-banner">
            <HomeBannerUser />
            <HomeBannerNews />
        </div>
        <div class="home-featured">

            <div class="home-nextprevious">
                <button v-on:click="homePreviousButton" class="button-previous">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.398287 7.54628L6.62846 13.7763C6.77256 13.9206 6.96491 14 7.17001 14C7.37512 14 7.56747 13.9206 7.71157 13.7763L8.17038 13.3176C8.46893 13.0188 8.46893 12.533 8.17038 12.2345L2.93875 7.00291L8.17619 1.76548C8.32028 1.62127 8.39984 1.42902 8.39984 1.22403C8.39984 1.01882 8.32028 0.826572 8.17619 0.682249L7.71738 0.223667C7.57317 0.0794572 7.38092 1.1355e-05 7.17582 1.13371e-05C6.97072 1.13192e-05 6.77836 0.0794571 6.63426 0.223667L0.398287 6.45942C0.25385 6.60408 0.174517 6.79723 0.174972 7.00257C0.174517 7.20869 0.25385 7.40173 0.398287 7.54628Z" />
                    </svg>
                </button>
                <button v-on:click="homeNextButton" class="button-next">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.398287 7.54628L6.62846 13.7763C6.77256 13.9206 6.96491 14 7.17001 14C7.37512 14 7.56747 13.9206 7.71157 13.7763L8.17038 13.3176C8.46893 13.0188 8.46893 12.533 8.17038 12.2345L2.93875 7.00291L8.17619 1.76548C8.32028 1.62127 8.39984 1.42902 8.39984 1.22403C8.39984 1.01882 8.32028 0.826572 8.17619 0.682249L7.71738 0.223667C7.57317 0.0794572 7.38092 1.1355e-05 7.17582 1.13371e-05C6.97072 1.13192e-05 6.77836 0.0794571 6.63426 0.223667L0.398287 6.45942C0.25385 6.60408 0.174517 6.79723 0.174972 7.00257C0.174517 7.20869 0.25385 7.40173 0.398287 7.54628Z" />
                    </svg>
                </button>
            </div>
            
            <div class="featured-title">FEATURED</div>
            <div ref="featuredContent" class="featured-content">
                
                <HomeGameElement game="blackjack" />
                <HomeGameElement game="mines" />
                <HomeGameElement game="towers" />
                <HomeGameElement game="dice" />
                <HomeGameElement game="crash" />


                <HomeGameElement game="battles" />
                <HomeGameElement game="cases" />
                <HomeGameElement game="duels" />
                <HomeGameElement game="roll" />

                
            </div>
        </div>
        <HomeDepositMethods />
        <Bets />
    </div>
</template>

<script>
    import Bets from '@/components/bets/Bets';
    import HomeBannerUser from '@/components/home/HomeBannerUser';
    import HomeBannerNews from '@/components/home/HomeBannerNews';
    import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
    import HomeGameElement from '@/components/home/HomeGameElement';
    import HomePlaceholderElement from '@/components/home/HomePlaceholderElement';
    import HomeDepositMethods from '@/components/home/HomeDepositMethods';

    export default {
        name: 'Home',
        metaInfo: {
            title: 'hoon.gg'
        },
        components: {
            Bets,
            HomeBannerUser,
            HomeBannerNews,
            HomeFeaturedLink,
            HomeGameElement,
            HomePlaceholderElement,
            HomeDepositMethods
        },
        data() {
            return {
                scrollAmount: 300, // Amount to scroll on each button click
            };
        },
        methods: {
            homePreviousButton() {
                // Scrolls left by scrollAmount
                const featuredContent = this.$refs.featuredContent;
                featuredContent.scrollBy({
                    left: -this.scrollAmount,
                    behavior: 'smooth'
                });
            },
            homeNextButton() {
                // Scrolls right by scrollAmount
                const featuredContent = this.$refs.featuredContent;
                featuredContent.scrollBy({
                    left: this.scrollAmount,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>

<style scoped>
.home {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 10px;
}

.home .home-banner {
    width: 1250px;
    display: flex;
    flex-wrap: wrap;
}


.home .home-featured,
.home .home-games {
    width: 1250px;
    position: relative;
    padding: 70px 0;
}

@keyframes flash {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.home .home-featured {
    margin-top: 55px;
    position: relative;
    
}

.home .home-featured::after {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit; 
    background: radial-gradient(68% 50% at 50% 110%, rgba(255, 58, 58, 0.5) 0%, rgba(32, 32, 32, 0) 80%);
    animation: flash 1s ease-in-out infinite alternate;
    z-index: 1;
    pointer-events: none;
}

.home .home-games {
    padding: 65px 0 40px 0;
    background: radial-gradient(45% 37% at 50% 105%, rgba(168, 69, 69, 0.65) 0%, rgba(32, 32, 27, 0) 100%);
}

.home .home-featured::before,
.home .home-games::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #FF3a3a 50%, rgba(2, 25, 44, 0) 100%);
    z-index: -1;
}

.home .home-games::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #5345a8 50%, rgba(2, 25, 44, 0) 100%);
}

.home .featured-title,
.home .games-title {
    width: 205px;
    height: 43px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 800;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

.home .featured-title::before,
.home .games-title::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(255deg, #FF3a3a -25%, #FF3a3a 100%);
    clip-path: inset(0 round 8px);
    z-index: -1;
}

.home .games-title::before {
    background: linear-gradient(255deg, #3b7eb7 -25%, #145081 100%);
}

.home-nextprevious {
    background-color: #3f3f3f;
    border-radius: 15px;
    height: 30px;
    width: 50px;

    position: absolute; 
    top: 3.5vh;
    left: 1vh;
}

.home button.button-previous,
.home button.button-next {
    width: 15px;
    height: 100%;
    z-index: 1;
    position: relative; 
    top: 0vh;
    left: 1vh;
}

.home button.button-previous {
    border-radius: 15px 0 0 15px;
}

.home button.button-next {
    border-radius: 0 15px 15px 0;
}

.home button.button-previous svg,
.home button.button-next svg {
    fill: #ffffff;
    transition: fill 0.3s ease;
}

.home button.button-next svg {
    transform: rotate(180deg);
}

.home button.button-previous:hover svg,
.home button.button-next:hover svg {
    fill: #ff3a3a;
}

.home .featured-content {
    display: flex;
    width: 1220px;
    height: 292px;
    overflow-x: auto;
    background: #252525;
    border: 15px solid #252525;
    border-radius: 25px;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    margin-left: 0px;
    z-index: 90;
}

.home .featured-content > *:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 1600px) {
    .home .home-banner,
    .home .home-featured,
    .home .home-games {
        width: 100%;
    }
}

@media only screen and (max-width: 950px) {
    .home {
        padding: 25px 10px 443px 10px;
    }

    .home .featured-content {
        width: 100%; /* Full width on mobile */
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        gap: 10px; /* Small gap between items */
        padding: 10px;
        border: 10px solid #252525;
    }

    .home .featured-content > * {
        flex: 0 0 auto;
        scroll-snap-align: start; /* Snap items to the start on scroll */
        min-width: 200px; /* Set minimum width for each item */
        max-width: 250px; /* Max width to fit smaller screens */
        margin-right: 35px; /* Adjust spacing */
    }

    .home .featured-content > * {
        min-width: 120px;
    }
}
</style>
