<template>
    
    <div class="login-credentials">


        <div class="credentials-element">
            <div class="element-title">PROFILE PICTURE</div>
            <div class="element-input">
                <input v-model="linkSettings" type="text" placeholder="ENTER LINK" />
            </div>
        </div>

        <div class="credentials-buttons">
            <button v-on:click="saveSettings()" class="button-action">
                <div class="button-inner">SAVE</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'SettingsInput',
        props: ['tab'],
        data() {
            return {
                loginUsername: null,
                linkSettings: null,
                loginPassword: null,
                loginPasswordConfirm: null
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow',
                'modalsSetData',
                'notificationShow',
                'authSendCredentialsLogin',
                'authSendCredentialsRegister',
                'generalSendPlayerSettings',
            ]),
            loginActionButton() {
                if(this.loginEmail === null || this.loginEmail.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered email is invalid.' });
                    return;
                }

                if(this.loginPassword === null || this.loginPassword.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered password is invalid.' });
                    return;
                }

                if(this.tab === 'register') {
                    if(this.loginPassword !== this.loginPasswordConfirm) {
                        this.notificationShow({ type: 'error', message: 'Your entered passwords does not match.' });
                        return;
                    }
                    this.modalsSetData({ typeCaptcha: 'credentialsRegister', data: { username: this.loginUsername, email: this.loginEmail, password: this.loginPassword } });
                } else {
                    this.modalsSetData({ typeCaptcha: 'credentialsLogin', data: { username: this.loginUsername, email: this.loginEmail, password: this.loginPassword } });
                }

                setTimeout(() => { this.modalsSetShow('Captcha'); }, 200);
            },

            saveSettings() {
                const extensionPattern = /\.(png|jpg|gif)$/i;

                console.log(this.linkSettings);
                if (this.linkSettings === null || this.linkSettings.trim() === '') {
                    this.notificationShow({ type: 'error', message: 'Your entered link is invalid.' });
                    return;
                }

                if (!extensionPattern.test(this.linkSettings.trim())) {
                    //console.log("hey");
                    this.notificationShow({ type: 'error', message: 'Your entered link must end with .png, .jpg, or .gif.' });
                    return;
                }

                //console.log('Valid link:', this.linkSettings.trim());
                this.modalsSetData({ typeCaptcha: 'saveSettings', data: { url: this.linkSettings.trim() } });

                setTimeout(() => { this.modalsSetShow('Captcha'); }, 200);
            }

        },
        computed: {
            ...mapGetters([ 
                'authSendLoginLoading'
            ])
        }
    }
</script>

<style scoped>
    .login-credentials {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-credentials .credentials-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }

    .login-credentials .credentials-element:first-child {
        margin-top: 0;
    }

    .login-credentials .element-title {
        font-size: 14px;
        font-weight: 800;
        color: #bbbfd0;
    }

    .login-credentials .element-input {
        width: 100%;
        height: 78px;
        position: relative;
        margin-top: 20px;
        padding: 1px;
        filter: drop-shadow(0px 4px 25px rgba(63, 15, 15, 0.35));
    }

    .login-credentials .element-input::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #181818 0%, #181818 100%);
clip-path: inset(0 round 8px);    }

    .login-credentials .element-input input {
        width: 100%;
        height: 100%;
        padding: 0 177px 0 25px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        background-color: #181818;
clip-path: inset(0 round 8px);    }

    .login-credentials .element-input input::placeholder {
        color: #ffffff;
    }

    .login-credentials button.button-forgot {
        height: 46px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translate(0, -50%);
        transition: all .2s;
    }
    .login-credentials button.button-forgot:hover {
        transition-duration: .2s; 
        opacity: .8;
    }
    .login-credentials button.button-forgot:active {
        transition-duration: .2s;
        scale: .9;
    }

    .login-credentials button.button-forgot::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #252525;
clip-path: inset(0 round 8px);        z-index: -1;
    }

    .login-credentials button.button-forgot::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background-color: #252525;
clip-path: inset(0 round 8px);        z-index: -1;
    }

    .login-credentials button.button-forgot .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
        background: #181818;
        clip-path: inset(0 round 8px);
    }

    .login-credentials button.button-forgot .button-inner span {
        font-size: 13px;
        font-weight: 800;
        color: #ff3a3a;
    }

    .login-credentials button.button-forgot .button-inner svg {
        width: 17px;
        height: 17px;
        display: none;
        fill: #ff3a3a;
    }

    .login-credentials .credentials-info {
        width: 100%;
        margin-top: 35px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
    }

    .login-credentials .credentials-info button.button-terms {
        text-decoration: underline;
        font-size: 14px;
        font-weight: 700;
        color: #db7d48;
    }

    .login-credentials .credentials-buttons {
        display: flex;
        align-items: center;
        margin-top: 35px;
    }

    .login-credentials button.button-toggle {
        width: 200px;
        height: 54px;
        position: relative;
        margin-right: 20px;
        padding: 1px;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35));
        transition: filter 0.3s ease;
        z-index: 1;
        transition: all .2s;
    }
    .button-toggle:hover {
        transition-duration: .1s;
        transform: translateY(-1px);
        opacity: 0.8;
    }
    .button-toggle:active {
        transition-duration: .2s;
        transform: translateY(2px);
    }

    .login-credentials button.button-toggle.button-active {
        filter: drop-shadow(0px 4px 25px rgba(230, 1, 1, 0.15)) 
                drop-shadow(0px 2px 25px rgba(63, 15, 15, 0.35));
    }

    .login-credentials button.button-action {
        width: 180px;
        height: 54px;
        position: relative;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35)) drop-shadow(0px 4px 25px rgba(230, 1, 1, 0.15));
        transition: all .2s;
    }

    .button-action:hover {
        transition-duration: .1s;
        transform: translateY(-1px);
        opacity: 0.8;
    }
    .button-action:active {
        transition-duration: .2s;
        transform: translateY(2px);
    }

    .login-credentials button.button-toggle::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #181818;
clip-path: inset(0 round 8px);        z-index: -1;
    }

    .login-credentials button.button-toggle.button-active::before {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ff3a3a 100%);
    }

    .login-credentials button.button-toggle::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background-color: #181818;
clip-path: inset(0 round 8px);        z-index: -1;
    }

    .login-credentials button.button-toggle .button-inner,
    .login-credentials button.button-action .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 800;
clip-path: inset(0 round 8px);    }

    .login-credentials button.button-toggle .button-inner {
        color: #bbbfd0;
        background-color: #181818;
    }

    .login-credentials button.button-toggle.button-active .button-inner {
        color: #ff3a3a;
        background: radial-gradient(60% 60% at 50% 50%, rgba(255, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), 
                    linear-gradient(255deg, rgba(255, 0, 0, 0.05) 0%, rgba(170, 0, 0, 0.05)00%);
    }

    .login-credentials button.button-action .button-inner {
        color: #ffffff;
        background: linear-gradient(250deg, #ff3a3a 0%, #ff3a3a 100%);
    }

    @media only screen and (max-width: 430px) {

        .login-credentials .element-input input {
            padding: 0 62px 0 25px;
        }


        .login-credentials button.button-forgot .button-inner span {
            display: none;
        }

        .login-credentials button.button-forgot .button-inner svg {
            display: block;
        }

        .login-credentials .credentials-buttons {
            width: 100%;
            flex-direction: column;
        }

        .login-credentials button.button-toggle,
        .login-credentials button.button-action {
            width: 100%;
            transition: all;
            transition-duration: 0.2s;
        }

        .button-action:hover {
            opacity: 0.8;
        }
        .button-action:focus {
            scale: 0.95;
        }

        .login-credentials button.button-toggle {
            margin-bottom: 10px;
            margin-right: 0;
        }

    }
</style>