<template>
  <div class="dice-game">
    <audio ref="soundRollDice" src="@/assets/sounds/rolldice.mp3"></audio>
    <main class="dice-game__main">
      <div class="dice-game__container">
        <div class="dice-game__layout">
          <!-- Betting Controls Section -->
          <section class="betting-controls">
            <div class="betting-controls__amount">
              <label class="betting-controls__label">Bet Amount</label>
              <div class="betting-controls__input-group">
                <input
                  type="number"
                  class="betting-controls__input"
                  v-model="betAmount"
                />
                <div class="betting-controls__buttons">
                  <button @click="betAmount /= 2" class="betting-controls__btn">1/2</button>
                  <button @click="betAmount *= 2" class="betting-controls__btn">2x</button>
                </div>
              </div>
            </div>
            
            <button
              class="betting-controls__submit"
              @click="handlePlaceBet"
              :disabled="isBetDisabled"
            >
              Place Bet
            </button>
          </section>

          <!-- Dice Game Section -->
          <section class="dice-game__gameplay">
            <div class="dice-game__display">
              <div class="dice-result">
                <span :class="['dice-result__number', { 'final-result': !animationTrigger }]" :data-roll="diceRoll">
                  {{ displayedRoll }}
                </span>
              </div>

              <div class="slider-markers">
                <span class="slider-markers__value">0</span>
                <span class="slider-markers__value2">25</span>
                <span class="slider-markers__value2">50</span>
                <span class="slider-markers__value2">75</span>
                <span class="slider-markers__value">100</span>
              </div>

              <div class="game-slider">
                <input
                  class="game-slider__input"
                  type="range"
                  name="range"
                  min="0"
                  max="100"
                  step="0.01"
                  v-model="sliderValue"
                  :style="{ background: sliderBackground }"
                  @input="handleSliderInput"
                />
              </div>

              <!-- Game Stats -->
              <div class="game-stats">
                <div class="game-stats__item">
                  <h3 class="game-stats__label">Multiplier</h3>
                  <div class="game-stats__value-container">
                    <span class="game-stats__symbol">×</span>
                    <input
                      type="number"
                      class="game-stats__value"
                      v-model="customMultiplier"
                      @input="handleMultiplierInput"
                      step="0.01"
                      min="1.01"
                      max="49.5"
                    />
                  </div>
                </div>
                
                <div class="game-stats__item">
                  <h3 class="game-stats__label">
                      Roll Under
                    </h3>
                  <div class="game-stats__value-container">
                    <input
                      type="number"
                      class="game-stats__value"
                      step="0.01"
                      min="0.01"
                      max="98"
                      :value="rollValue"
                      disabled
                    />
                    <span class="game-stats__symbol">⇄</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="dice-game__bets-wrapper">
        <Bets />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Bets from '@/components/bets/Bets';

export default {
  name: 'Dice',
  metaInfo: {
    title: 'Dice - hoon.gg'
  },
  components: {
    Bets
  },
  data() {
    return {
      diceResult: null,
      sliderValue: 50,
      betAmount: 1,
      isBetDisabled: false,
      animationTrigger: false,
      displayedRoll: '?',
      isRollUnder: true,
      customMultiplier: null,
    };
  },
  computed: {
    ...mapGetters([
      'socketSendLoading',
      'authUser',
      'diceRoll',
      'soundRollDice',
    ]),
    displayedResult() {
      return this.isRolling ? '?' : this.diceRoll;
    },
    rollValue() {
      if (this.sliderValue === 50) return 50;

      if (this.isRollUnder) {
        if (this.sliderValue < 50) {
          return parseFloat((2 + (this.sliderValue * (48 / 50))).toFixed(2));
        } else {
          return parseFloat((50 + ((this.sliderValue - 50) * (48 / 50))).toFixed(2));
        }
      } else {
        if (this.sliderValue < 50) {
          return parseFloat((98 - (this.sliderValue * (48 / 50))).toFixed(2));
        } else {
          return parseFloat((50 - ((this.sliderValue - 50) * (48 / 50))).toFixed(2));
        }
      }
    },
    multiplier() {
      if (this.isRollUnder) {
        return parseFloat((99 / this.rollValue).toFixed(2));
      } else {
        return parseFloat((99 / (100 - this.rollValue)).toFixed(2));
      }
    },
    sliderBackground() {
      const percentage = this.sliderValue;
      const color1 = this.isRollUnder ? 'var(--color-slider2)' : 'var(--color-slider1)';
      const color2 = this.isRollUnder ? 'var(--color-slider1)' : 'var(--color-slider2)';
      return `linear-gradient(to right, ${color1} ${percentage}%, ${color2} ${percentage}%)`;
    }
  },
  watch: {
    diceRoll() {
      this.triggerDiceRollAnimation();
    },
    multiplier: {
      handler(newValue) {
        if (!this.customMultiplier) {
          this.customMultiplier = newValue;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions([
      'socketConnectDice', 
      'socketDisconnectDice',
      'notificationShow', 
      'diceSendBetSocket',
    ]),
    handleSliderInput() {
      // Update customMultiplier when slider moves
      this.customMultiplier = this.multiplier;
    },
    handleMultiplierInput() {
      const mult = parseFloat(this.customMultiplier);
      if (mult >= 1.01 && mult <= 49.5) {
        let targetRoll;
        if (this.isRollUnder) {
          targetRoll = 99 / mult;
        } else {
          targetRoll = 100 - (99 / mult);
        }
        
        // Convert target roll to slider value
        if (targetRoll <= 50) {
          this.sliderValue = ((targetRoll - 2) * (50 / 48)).toFixed(2);
        } else {
          this.sliderValue = (50 + ((targetRoll - 50) * (50 / 48))).toFixed(2);
        }
      }
    },
    handlePlaceBet() {
      const amount = this.betAmount;
      const rollValue = this.rollValue;
      const multiplierSet = this.multiplier;
      const isUnder = this.isRollUnder;

      const data = { 
        amount: amount, 
        rollunder: rollValue, 
        multiplier: multiplierSet,
        isUnder: isUnder 
      };
      this.diceSendBetSocket(data);

      this.isBetDisabled = true;
      setTimeout(() => {
        this.isBetDisabled = false;
      }, 2000);
    },
    triggerDiceRollAnimation() {
      this.animationTrigger = true;
      this.displayedRoll = '?';
      const rollAudio = this.$refs.soundRollDice;
      rollAudio.volume = 0.5;
      rollAudio.currentTime = 0;
      rollAudio.play();

      let intervalStep = 50;
      let maxRollTime = 1200;
      let elapsedTime = 0;

      const rollInterval = setInterval(() => {
        this.displayedRoll = Math.floor(Math.random() * 100) + 1;

        elapsedTime += intervalStep;

        if (elapsedTime >= maxRollTime) {
          clearInterval(rollInterval);
          this.displayedRoll = this.diceRoll;
          this.animationTrigger = false;
        } else {
          intervalStep += 20;
        }
      }, intervalStep);
    }
  },
  created() {
    this.socketConnectDice();
  },
  beforeRouteLeave(to, from, next) {
    this.socketDisconnectDice();
    next();
  },
};
</script>

<style>
:root {
/* Colors */
--color-primary: #ff3a3a;
--color-primary-dark: #bb2929;
--color-background: #3a1617;
--color-surface: #471a1c;
--color-surface-dark: #532729;
--color-border: #553939;
--color-slider1: #e9113c;
--color-slider2: #00e701;
--color-text: #ffffff;
--color-text-secondary: #a9a9a9;

/* Spacing */
--spacing-xs: 5px;
--spacing-sm: 8px;
--spacing-md: 10px;
--spacing-lg: 20px;
--spacing-xl: 24px;

/* Border Radius */
--border-radius: 8px;

/* Shadows */
--shadow-default: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.dice-game {
min-height: 100vh;

}

@keyframes bounce {
0% { transform: scale(1); }
50% { transform: scale(1.2); }
100% { transform: scale(1); }
}

.dice-result__number.final-result {
animation: bounce 0.3s ease-out;
}




/* Layout */
.dice-game__main {
min-height: 100vh;
padding-top: 96px;
padding-bottom: var(--spacing-xl);
display: flex;
flex-direction: column;
gap: var(--spacing-xl);
}

.dice-game__container {
max-width: 1200px;
margin: 0 auto;
padding: var(--spacing-lg);
border: 1px solid var(--color-border);
background-color: var(--color-background);
border-radius: var(--border-radius);
box-shadow: var(--shadow-default);
width: 100%;
}

.dice-game__layout {
display: flex;
flex-direction: row-reverse;
gap: var(--spacing-xl);
padding: var(--spacing-xl) 0;
}

.dice-game__bets-wrapper {
max-width: 1200px;
margin: 0 auto;
width: 100%;
padding: 0 var(--spacing-lg);
}

/* Betting Controls */
.betting-controls {
width: 300px;
background: var(--color-surface);
padding: var(--spacing-lg);
border-radius: var(--border-radius);
}

.betting-controls__label {
color: var(--color-text);
font-size: 1.1rem;
margin-bottom: var(--spacing-sm);
display: block;
}

.betting-controls__input-group {
position: relative;
margin-bottom: var(--spacing-lg);
}

.betting-controls__input {
width: 100%;
height: 40px;
background: var(--color-surface-dark);
color: var(--color-text);
padding: 0 var(--spacing-md);
border-radius: var(--border-radius);
font-size: 1rem;
}

.betting-controls__buttons {
position: absolute;
right: var(--spacing-md);
top: 50%;
transform: translateY(-50%);
display: flex;
gap: var(--spacing-xs);
}

.betting-controls__btn {

color: var(--color-text);
border: none;
padding: var(--spacing-xs) var(--spacing-md);
border-radius: var(--border-radius);
cursor: pointer;
transition: background 0.3s;
}

.betting-controls__btn:hover {
background: #666;
}

.betting-controls__submit {
width: 100%;
height: 40px;
background: var(--color-primary);
color: var(--color-text);
border: none;
border-radius: var(--border-radius);
cursor: pointer;
transition: background 0.3s;
}

.betting-controls__submit:hover {
background: var(--color-primary-dark);
}

.betting-controls__submit:disabled {
background: #a9a9a9;
}

/* Dice Game Display */
.dice-game__gameplay {
flex: 1;
background: var(--color-surface-dark);
border-radius: var(--border-radius);
padding: var(--spacing-lg);
}

.dice-game__display {
max-width: 700px;
margin: 0 auto;
}

/* Dice Result */
.dice-result {
height: 140px;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: var(--spacing-lg);
}

.dice-result__number {
font-size: clamp(60px, 18vw, 140px);
font-weight: 600;
color: var(--color-text);
opacity: 0.7;
user-select: none;
}

/* Slider */
.slider-markers {
display: flex;
justify-content: space-between;
color: var(--color-text);
font-weight: 600;
margin-bottom: var(--spacing-sm);
}

.slider-markers .slider-markers__value2 {
  margin-left: 20px;
}

.game-slider {
margin: var(--spacing-md) 0;
width: 100%;
}

.game-slider__input {
width: 100%;
height: 8px;
border-radius: 4px;
appearance: none;
-webkit-appearance: none;
}

.game-slider__input::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none;
width: 20px;
height: 20px;
border-radius: 50%;
cursor: pointer;
background: #FFFFFF; 
}

.game-slider__input::-moz-range-thumb {
width: 20px;
height: 20px;
border-radius: 50%;
cursor: pointer;
border: none;
  background: #FFFFFF; 
}


.game-stats {
display: flex;
justify-content: space-between;
margin-top: var(--spacing-xl);
gap: var(--spacing-lg);
}

.game-stats__item {
flex: 1;
}

.game-stats__label {
color: var(--color-text-secondary);
font-weight: bold;
margin-bottom: var(--spacing-sm);
}

.game-stats__value-container {
position: relative;
width: 100%;
}

.game-stats__value {
width: 100%;
height: 35px;
background: var(--color-surface);
border-radius: var(--border-radius);
padding: 0 var(--spacing-xl);
color: var(--color-text);
border: none;
text-align: center;
}

.game-stats__symbol {
position: absolute;
top: 50%;
transform: translateY(-50%);
color: var(--color-text-secondary);
}

.game-stats__value-container:first-child .game-stats__symbol {
left: var(--spacing-md);
}

.game-stats__value-container:last-child .game-stats__symbol {
right: var(--spacing-md);
}

.roll-toggle-btn {
  background: none;
  border: none;
  color: var(--color-text-secondary);
  font-weight: bold;

  padding: 0;
  font-size: inherit;
}


.game-stats__value {
  /* Modified to show it's editable where applicable */
  cursor: text;
}

.game-stats__value:disabled {
  cursor: default;
}

/* Mobile Responsive */
@media (max-width: 1024px) {
.dice-game__layout {
  flex-direction: column;
}

.betting-controls {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
}

@media (max-width: 768px) {
.dice-game__main {
  padding-top: 64px;
}

.game-stats {
  flex-direction: column;
  align-items: center;
}

.game-stats__value-container {
  width: 100%;
  max-width: 200px;
}

.dice-result__number {
  font-size: clamp(40px, 15vw, 100px);
}

.dice-game__bets-wrapper {
    position: absolute;
    left: 0;
    bottom: -250px;
    margin-top: 0;
}
}

@media (max-width: 480px) {
.dice-game__container {
  padding: var(--spacing-sm);
}

.betting-controls,
.dice-game__gameplay {
  padding: var(--spacing-md);
} 

.dice-game__bets-wrapper {
  padding: 0 var(--spacing-sm);
  position: absolute;
  left: 0;
  bottom: -500px;
  margin-top: 0;
}

}
</style>