import { render, staticRenderFns } from "./GiftWithdraw.vue?vue&type=template&id=4c3aa445&scoped=true"
import script from "./GiftWithdraw.vue?vue&type=script&lang=js"
export * from "./GiftWithdraw.vue?vue&type=script&lang=js"
import style0 from "./GiftWithdraw.vue?vue&type=style&index=0&id=4c3aa445&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3aa445",
  null
  
)

export default component.exports