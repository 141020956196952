<template>
    <div class="modal-faq">
        <div class="faq-header">
            <span class="gradient-red">QUESTIONS AND ANSWERS</span>
            <p>Here are some answers to frequently asked questions we have collected for you. New content is added on 
            a regular basis to help you quickly overcome any difficulties. Of course you can also contact our support 
            or ask questions directly in our Discord.</p>
        </div>
        <div class="faq-list">

            <FaqElement title="What is hoon.gg?" text="hoon.gg is a virtual gaming platform designed for players to multiply their cryptocurrency! We are the most innovative arcade, striving to bring you the best experiences." />
        <FaqElement title="Why do you need my credentials?" text="In order for us to automate our deposit and withdrawal system, we utilize our P2P system to facilitate seamless transactions for depositors and withdrawers. hoon.gg ensures user security and we do not store your password. If you feel unsafe with us having your credentials, you can simply invalidate them by changing your password." />
        <FaqElement title="What are the coins?" text="Our balance is displayed as coins, with a conversion rate of 1 coin to 1 cryptocurrency unit. We allow you to withdraw these coins at a 1-to-1 rate." />
        <FaqElement title="Can I transfer coins to another account?" text="Yes, you can transfer coins to another account by simply clicking on a user's profile and selecting the Tip User button." />
        <FaqElement title="I am missing coins, what now?" text="Please ensure you have not given access to your hoon.gg account. In case you feel compromised, we will assist you with regaining access to your account. However, we do not issue balance adjustments for losses, as account security is your responsibility. If this involves a game issue, please open a ticket in our Discord." />
        <FaqElement title="I deposited with cryptocurrency and I still have not received my deposit." text="If you haven’t received your deposit after 3 confirmations, please open a ticket in our Discord explaining the situation and providing transaction IDs." />
        <FaqElement title="I have discovered a vulnerability or an exploit, what now?" text="Join our Discord and create a ticket. You will be rewarded for reporting any vulnerability or exploit." />
        <FaqElement title="What is 'Rain Abuse'?" text="The rain coins distributed throughout the 30-minute rain feature are intended to be used exclusively on the games provided on our site. Accumulating free rain coins over an extended period without using them genuinely in the games is considered rain abuse and will result in a ban. Please note, all rain bans are permanent and will not be lifted." />
        <FaqElement title="How much commission do I get when my referrals place a bet?" text="Every time someone you've referred places a bet, you receive 1% of that overall wager. For instance, if someone wagers 1,000,000 coins, you'd receive 10,000 coins." />
        <FaqElement title="How does the deposit and withdrawal system work?" text="We use a Peer-to-Peer (P2P) system to facilitate the transactions on our site. There may be extended wait times for deposits. In order to deposit, someone must withdraw, and vice versa. hoon.gg will subsidize long queue times to ensure user satisfaction." />
        <FaqElement title="Is there a maximum bet or profit?" text="Yes, each game has a maximum bet and a maximum profit allotted for each round. You can find this information inside each game." />
        <FaqElement title="What happens if my internet disconnects during a game?" text="Whether your client is connected or disconnected, the auto-cashout feature will work as intended. We highly recommend using this feature if your internet connection is unreliable." />
        <FaqElement title="Does the game have a maximum multiplier?" text="Our games have no limit! However, you will be automatically cashed out if you hit our maximum profit." />
        <FaqElement title="How do I know that hoon.gg is fair & doesn't rig results?" text="hoon.gg uses a provably fair system. You can check all game results with our provably fair system, found at the bottom of our website." />
        <FaqElement title="Can I make money by promoting the website?" text="If you're an influencer looking to be sponsored by us, please join our Discord server and open a ticket." />

        </div>
    </div>
</template>

<script>
    import FaqElement from '@/components/faq/FaqElement';

    export default {
        name: 'ModalFaq',
        components: {
            FaqElement
        }
    }
</script>

<style scoped>
    .modal-faq {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        background: linear-gradient(#171716, #37191a) padding-box;
    }

    .modal-faq .faq-header {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #613333;
    }

    .modal-faq .faq-header span  {
        font-size: 32px;
        font-weight: 900;
    }

    .modal-faq .faq-header p  {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #dbdbdb;
    }

    .modal-faq .faq-list {
        width: 100%;
        height: 600px;
        padding: 30px 10px 30px 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-faq .faq-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-faq .faq-list::-webkit-scrollbar-track {
        margin: 30px 0;
        background-color: #252525;
    }

    .modal-faq .faq-list::-webkit-scrollbar-thumb {
        background-color: #ff3a3a;
    }

    @media only screen and (max-width: 1190px) {

        .modal-faq {
            width: calc(100vw - 20px);
        }

    }

    @media only screen and (max-width: 700px) {

        .modal-faq {
            padding: 80px 15px 0 15px;
        }

    }
</style>
