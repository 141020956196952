<template>
    <div class="navbar-cashier">
        <button v-on:click="modalsSetShow('Cashier')" class="button-cashier">
            <div class="button-inner">
                <IconCashier />
                <span>Wallet</span>
            </div>
        </button>
        <div class="cashier-balance">
            <div class="balance-inner">
                <img src="@/assets/img/icons/dollarsNav.svg" alt="icon" />
                <AmountNumber v-bind:amount="authUser.user.balance" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AmountNumber from '@/components/AmountNumber';
    import IconCashier from '@/components/icons/IconCashier';

    export default {
        name: 'NavbarCashier',
        components: {
            AmountNumber,
            IconCashier
        },
        methods: {
            ...mapActions([
                'modalsSetShow'
            ])
        },
        computed: {
            ...mapGetters([
                'authUser'
            ]),
            navbarGetBalance() {
               return parseFloat(Math.floor(this.authUser.user.balance * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
           }
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

    .navbar-cashier {
        display: flex;
        align-items: center;
        font-family: 'Roboto', sans-serif;
    }

    .navbar-cashier button.button-cashier {
        height: 50px;
        position: relative;
        margin-right: 15px;
        padding: 1px;
        transition: all .2s;
    }

    .button-cashier:hover {
        transition-duration: .2s; 
        opacity: .8;
    }
    .button-cashier:active {
        transition-duration: .2s;
        scale: .9;
    }
    .navbar-cashier button.button-cashier::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: inset(0 round 8px);
    }

    .navbar-cashier button.button-cashier .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 14px;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        color: #ffffff;
        background: linear-gradient(255deg, #252525 0%, #252525 100%);
        clip-path: inset(0 round 8px);
    }

    .navbar-cashier button.button-cashier .button-inner svg {
        margin-right: 22px;
        fill: #FF3A3A;
    }

    .navbar-cashier .cashier-balance {
        width: 190px;
        height: 50px;
        position: relative;
        padding: 1px;
    }

    .navbar-cashier .cashier-balance::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: inset(0 round 8px);
        z-index: 0;
    }

    .navbar-cashier .cashier-balance button.button-free {
        height: 21px;
        position: absolute;
        top: -11px;
        right: 0;
        z-index: 1;
    }

    .navbar-cashier .cashier-balance button.button-free .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 7px;
        font-size: 10px;
        color: #ffffff;
        background: linear-gradient(255deg, #252525 0%, #252525 100%);
        clip-path: inset(0 round 8px);
    }

    .navbar-cashier .balance-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        background: linear-gradient(255deg, #252525 0%, #252525 100%);
        clip-path: inset(0 round 8px);
        z-index: 1;
    }

    .navbar-cashier .balance-inner img {
        width: 24px;
        height: 24px;
    }

    .navbar-cashier .inner-value {
        font-size: 12px;
        color: #c1c1c1;
    }

    .navbar-cashier .inner-value span {
        font-size: 16px;
        color: #ffffff;
    }

    @media only screen and (max-width: 1175px) {

        .navbar-cashier button.button-cashier {
            height: 38px;
        }

        .navbar-cashier button.button-cashier .button-inner {
            padding: 0 13px;
        }

        .navbar-cashier button.button-cashier .button-inner svg {
            width: 14px;
        }


        .navbar-cashier button.button-cashier .button-inner svg {
            margin-right: 0;
        }

        .navbar-cashier button.button-cashier .button-inner span {
            display: none;
        }


        .navbar-cashier .cashier-balance {
            width: auto;
            height: 38px;
        }

        .navbar-cashier .cashier-balance button.button-free {
            height: 16px;
            top: -8px;
        }

        .navbar-cashier .cashier-balance button.button-free .button-inner {
            padding: 0 6px;
            font-size: 8px;
        }

        .navbar-cashier .balance-inner {
            padding: 0 12px 0 5px;
        }

        .navbar-cashier .balance-inner img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
        }

    }
</style>
