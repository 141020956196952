<template>
    <div class="roll-game-win" v-bind:class="{
        'win-high': multiplier / 100 >= 20,
        'win-mid': multiplier / 100 < 20 && multiplier / 100 >= 5,
        'win-low': multiplier / 100 < 5 && multiplier / 100 >= 2
    }">
        <div class="win-box">
            <div class="box-inner">
                <div class="inner-card">
                    <div class="card-inner">
                        <div class="inner-amount">
                            <img src="@/assets/img/icons/dollarsNav.svg" alt="icon" />
                            <div class="amount-value">
                                <span>{{rollFormatValue(winAmount).split('.')[0]}}</span>.{{rollFormatValue(winAmount).split('.')[1]}}
                            </div>
                        </div>
                        <div class="inner-multiplier">{{parseFloat(multiplier / 100).toFixed(2)}}x</div>
                        <div class="inner-win">
                            <div v-bind:style="{ 
                                'background-image': 'url(' + rollGetItem.image + ')',
                                'background-repeat': 'no-repeat',
                                'background-position': 'center',
                                'background-size': '110px auto'
                            }" class="win-image"></div>
                            {{ rollGetItem.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="text-red-gradient">YOUR BALANCE HAS BEEN CREDITED!</span>
    </div>
</template>

<script>
    export default {
        name: 'RollGameWin',
        props: [
            'winAmount', 
            'multiplier'
        ],
        data() {
            return {
                rollItems: {
                    1: [
                        { name: 'Stack of White Chips', image: 'https://hoon.gg/newitems/white.png' }, 
                        { name: 'Bag of White Chips', image: 'https://hoon.gg/newitems/whitebag.png' }, 
                        { name: 'Stack of White Chips', image: 'https://hoon.gg/newitems/white.png' }, 
                        { name: 'Bag of White Chips', image: 'https://hoon.gg/newitems/whitebag.png' }, 
                        { name: 'Stack of White Chips', image: 'https://hoon.gg/newitems/white.png' }, 
                        { name: 'Bag of White Chips', image: 'https://hoon.gg/newitems/whitebag.png' }, 
                        { name: 'Stack of White Chips', image: 'https://hoon.gg/newitems/white.png' }, 
                        { name: 'Bag of White Chips', image: 'https://hoon.gg/newitems/whitebag.png' }, 
                        { name: 'Stack of White Chips', image: 'https://hoon.gg/newitems/white.png' }, 
                    ],
                    2: [
                        { name: 'Stack of Gold Chips', image: 'https://hoon.gg/newitems/gold.png' }, 
                        { name: 'Bag of Gold Chips', image: 'https://hoon.gg/newitems/goldbag.png' }, 
                        { name: 'Stack of Gold Chips', image: 'https://hoon.gg/newitems/gold.png' }, 
                        { name: 'Bag of Gold Chips', image: 'https://hoon.gg/newitems/goldbag.png' }, 
                        { name: 'Stack of Gold Chips', image: 'https://hoon.gg/newitems/gold.png' }, 
                        { name: 'Bag of Gold Chips', image: 'https://hoon.gg/newitems/goldbag.png' }, 
                        { name: 'Stack of Gold Chips', image: 'https://hoon.gg/newitems/gold.png' }, 
                        { name: 'Bag of Gold Chips', image: 'https://hoon.gg/newitems/goldbag.png' }, 
                        { name: 'Stack of Gold Chips', image: 'https://hoon.gg/newitems/gold.png' }, 
                    ],
                    3: [
                        { name: 'Stack of Blue Chips', image: 'https://hoon.gg/newitems/blue.png' }, 
                        { name: 'Bag of Blue Chips', image: 'https://hoon.gg/newitems/bluebag.png' }, 
                        { name: 'Stack of Blue Chips', image: 'https://hoon.gg/newitems/blue.png' }, 
                        { name: 'Bag of Blue Chips', image: 'https://hoon.gg/newitems/bluebag.png' }, 
                        { name: 'Stack of Blue Chips', image: 'https://hoon.gg/newitems/blue.png' }, 
                        { name: 'Bag of Blue Chips', image: 'https://hoon.gg/newitems/bluebag.png' }, 
                        { name: 'Stack of Blue Chips', image: 'https://hoon.gg/newitems/blue.png' }, 
                        { name: 'Bag of Blue Chips', image: 'https://hoon.gg/newitems/bluebag.png' }, 
                        { name: 'Stack of Blue Chips', image: 'https://hoon.gg/newitems/blue.png' }, 
                    ],
                    4: [
                        { name: 'Stack of Red Chips', image: 'https://hoon.gg/newitems/red.png' }, 
                        { name: 'Bag of Red Chips', image: 'https://hoon.gg/newitems/redbag.png' }, 
                        { name: 'Stack of Red Chips', image: 'https://hoon.gg/newitems/red.png' }, 
                        { name: 'Bag of Red Chips', image: 'https://hoon.gg/newitems/redbag.png' }, 
                        { name: 'Stack of Red Chips', image: 'https://hoon.gg/newitems/red.png' }, 
                        { name: 'Bag of Red Chips', image: 'https://hoon.gg/newitems/redbag.png' }, 
                        { name: 'Stack of Red Chips', image: 'https://hoon.gg/newitems/red.png' }, 
                        { name: 'Bag of Red Chips', image: 'https://hoon.gg/newitems/redbag.png' }, 
                        { name: 'Stack of Red Chips', image: 'https://hoon.gg/newitems/red.png' }, 
                    ],
                    5: [
                        { name: 'Stack of Purple Chips', image: 'https://hoon.gg/newitems/purple.png' }, 
                        { name: 'Bag of Purple Chips', image: 'https://hoon.gg/newitems/purplebag.png' }, 
                        { name: 'Stack of Purple Chips', image: 'https://hoon.gg/newitems/purple.png' }, 
                        { name: 'Bag of Purple Chips', image: 'https://hoon.gg/newitems/purplebag.png' }, 
                        { name: 'Stack of Purple Chips', image: 'https://hoon.gg/newitems/purple.png' }, 
                        { name: 'Bag of Purple Chips', image: 'https://hoon.gg/newitems/purplebag.png' }, 
                        { name: 'Stack of Purple Chips', image: 'https://hoon.gg/newitems/purple.png' }, 
                        { name: 'Bag of Purple Chips', image: 'https://hoon.gg/newitems/purplebag.png' }, 
                        { name: 'Stack of Purple Chips', image: 'https://hoon.gg/newitems/purple.png' }, 
                    ]
                }
            }
        },  
        methods: {
            rollFormatValue(value) {
                return parseFloat(Math.floor(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            rollGetItem() {
                const multiplier = this.multiplier / 100;
                let item = this.rollItems[5][9];

                if(multiplier <= 2.5) {
                    item = this.rollItems[1][Math.round((multiplier - 1) / (1.5 / 9))];
                } else if(multiplier <= 5) {
                    item = this.rollItems[2][Math.round((multiplier - 2.5) / (2.5 / 9))];
                } else if(multiplier <= 50) {
                    item = this.rollItems[3][Math.round((multiplier - 5) / (45 / 9))];
                } else if(multiplier <= 100) {
                    item = this.rollItems[4][Math.round((multiplier - 50) / (50 / 9))];
                } else if(multiplier <= 1000) {
                    item = this.rollItems[5][Math.round((multiplier - 100) / (900 / 9))];
                }

                return item;
            }
        }
    }
</script>

<style scoped>
    .roll-game-win {
        width: 330px;
        height: 400px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 20px 15px;
        border-radius: 15px;
        background: #252525;
        border: 1px solid rgba(183, 59, 59, 0.5);
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5), inset 0px 0px 25px rgba(0, 0, 0, 0.35);
    }

    .roll-game-win span {
        font-size: 14px;
        font-weight: 800;
    }

    .roll-game-win .win-box {
        width: 100%;
        height: 325px;
        padding: 1px;
        border-radius: 8px;
        background: #252525;
    }

    .roll-game-win.win-high .win-box {
        background: linear-gradient(180deg, rgba(222, 68, 34, 0.5) 0%, rgba(222, 68, 34, 0.25) 50%, #de4422 100%);
    }

    .roll-game-win.win-mid .win-box {
        background: linear-gradient(180deg, rgba(255, 183, 3, 0.5) 0%, rgba(255, 183, 3, 0.25) 50%, #ffe603 100%);
    }

    .roll-game-win.win-low .win-box {
        background: linear-gradient(180deg, rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.25) 50%, #ff0000 100%);
    }

    .roll-game-win .box-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #062137;
    }

    .roll-game-win.win-high .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(222, 35, 35, 0.75) -30%, rgba(222, 68, 34, 0.75) -16%, rgba(167, 43, 35, 0.431195) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(146, 25, 25, 0.25);
    }

    .roll-game-win.win-mid .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 183, 3, 0.75) -30%, rgba(255, 183, 3, 0.75) -16%, rgba(255, 183, 3, 0.43) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(255, 168, 0, 0.25);
    }

    .roll-game-win.win-low .box-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 0, 0, 0.75) -30%, rgba(255, 0, 0, 0.75) -16%, rgba(255, 0, 0, 0.26) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(194, 35, 35, 0.25);
    }

    .roll-game-win .inner-card {
        width: 195px;
        height: 275px;
        position: relative;
        flex-shrink: 0;
        margin-right: 4px;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    }

    .roll-game-win .inner-card::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #10283a;
        clip-path: inset(0 round 8px);
    }

    .roll-game-win.win-high .inner-card::before {
        background: linear-gradient(180deg, rgba(222, 68, 34, 0.5) 0%, rgba(222, 68, 34, 0.25) 50%, #de4422 100%);
    }

    .roll-game-win.win-mid .inner-card::before {
        background: linear-gradient(180deg, rgba(255, 183, 3, 0.5) 0%, rgba(255, 183, 3, 0.25) 50%, #ffe603 100%);
    }

    .roll-game-win.win-low .inner-card::before {
        background: linear-gradient(180deg, rgba(255, 0, 0, 0.5) 0%, rgba(255, 0, 0, 0.25)0%, #ff0000 100%);
    }

    .roll-game-win .card-inner {
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 1px;
        left: 1px;
        padding: 15px;
        background-color: #252525;
        clip-path: inset(0 round 8px);
        z-index: 1;
    }

    .roll-game-win.win-high .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(222, 35, 35, 0.75) -30%, rgba(222, 68, 34, 0.75) -16%, rgba(167, 43, 35, 0.431195) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(146, 25, 25, 0.25);
    }

    .roll-game-win.win-mid .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 183, 3, 0.75) -30%, rgba(255, 183, 3, 0.75) -16%, rgba(255, 183, 3, 0.43) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(255, 168, 0, 0.25);
    }

    .roll-game-win.win-low .card-inner {
        background: radial-gradient(230% 105% at 50% 15%, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.46) 30%, rgba(0, 0, 0, 0.42) 65%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 0, 0, 0.75) -30%, rgba(255, 0, 0, 0.75)16%, rgba(255, 0, 0, 0.26) 30%, rgba(0, 0, 0, 0) 100%), #252525;
        box-shadow: inset 0px 0px 75px rgba(194, 35, 35, 0.25);
    }

    .roll-game-win .inner-amount {
        display: flex;
        align-items: center;
    }

    .roll-game-win .inner-amount img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }

    .roll-game-win .amount-value {
        font-size: 12px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .roll-game-win .amount-value span {
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    .roll-game-win .inner-multiplier {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-game-win .inner-win {
        height: calc(100% - 57px);
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
    }

    .roll-game-win .win-image {
        width: 100%;
        height: 110px;
    }
</style>
