var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"duels-games-element",class:{ 
    'element-full': _vm.game.bets.length >= _vm.game.playerCount,
    'element-completed': _vm.game.state === 'completed'
},on:{"click":_vm.duelsGameButton}},[_c('div',{staticClass:"element-info"},[_c('div',{staticClass:"info-bet"},[_vm._v(" BET: "),_c('div',{staticClass:"bet-box"},[_c('img',{attrs:{"src":require("@/assets/img/icons/dollarsNav.svg"),"alt":"icon"}}),_c('div',{staticClass:"box-value"},[_c('span',[_vm._v(_vm._s(_vm.duelsFormatValue(_vm.game.amount).split('.')[0]))]),_vm._v("."+_vm._s(_vm.duelsFormatValue(_vm.game.amount).split('.')[1])+" ")]),_vm._v(" / "),_c('div',{staticClass:"box-value"},[_c('span',[_vm._v(_vm._s(_vm.duelsFormatValue(_vm.game.amount * _vm.game.playerCount * 0.95).split('.')[0]))]),_vm._v("."+_vm._s(_vm.duelsFormatValue(_vm.game.amount * _vm.game.playerCount * 0.95).split('.')[1])+" ")])])]),_c('div',{staticClass:"info-creator",class:[
            'creator-' + _vm.duelsGetRank(_vm.game.bets[0].user),
            'creator-' + _vm.duelsGetLevelColor(_vm.game.bets[0].user)
        ]},[_vm._v(" CREATED BY: "),_c('div',{staticClass:"creator-avatar"},[_c('img',{staticClass:"avatar-image",attrs:{"src":_vm.game.bets[0].user.avatar,"alt":"User Avatar"}})]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.game.bets[0].user.username)}})])]),_c('div',{staticClass:"element-users"},[_c('div',{staticClass:"users-inner"},[_c('IconUserGradient'),(_vm.game.bets.length >= _vm.game.playerCount)?_c('div',{staticClass:"inner-full"},[_c('span',{staticClass:"gradient-red"},[_vm._v(_vm._s(_vm.game.playerCount))])]):_c('div',{staticClass:"inner-waiting"},[_c('span',[_vm._v(_vm._s(_vm.game.bets.length))]),_vm._v("/"+_vm._s(_vm.game.playerCount)+" ")])],1),_c('div',{staticClass:"users-progress",style:({ 'height': (100 / _vm.game.playerCount) * _vm.game.bets.length + '%' })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }