<template>
    <div class="amount-number">
        <span>{{amountFormatValue(amountCurrent).split('.')[0]}}</span>.{{amountFormatValue(amountCurrent).split('.')[1]}}
        <audio ref="cashSound" src="@/assets/sounds/cash.mp3"></audio>
    </div>
</template>

<script>
    export default {
        name: 'AmountNumber',
        props: ['amount'],
        data() {
            return {
                amountInterval: null,
                amountCurrent: 0
            }
        },
        methods: {
            amountFormatValue(value) {
                return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            playSound() {
                this.$refs.cashSound.currentTime = 0;
                this.$refs.cashSound.play();
            },
        },
        watch: {
            'amount': {
                handler() {
                    clearInterval(this.amountInterval);

                    //console.log(this.amount, this.amountCurrent);
                    const step = (this.amount - this.amountCurrent) / 60;

                    if (step > 0) {
                        this.playSound();
                    }
                    
                    this.amountInterval = setInterval(() => { 
                        this.amountCurrent += step;

                        if((step >= 0 && this.amountCurrent >= this.amount) || (step <= 0 && this.amountCurrent <= this.amount)) {
                            clearInterval(this.amountInterval); 
                            this.amountCurrent = this.amount;
                        }
                    }, 1000 / 60);
                }
            }
        },
        mounted() {
            this.amountCurrent = this.amount;
        }
    }
</script>

<style scoped>
    .amount-number {
        font-size: 12px;
        color: #c1c1c1;
    }

    .amount-number span {
        font-size: 16px;
        color: #ffffff;
    }

    @media only screen and (max-width: 1175px) {

        .amount-number {
            font-size: 10px;
        }

        .amount-number span {
            font-size: 14px;
        }
    }
</style>